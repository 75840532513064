var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-dashboard-layout',[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"fill-width d-flex justify-space-between flex-wrap"},[_c('div',[_c('v-list-item',{staticClass:"px-2"},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.user.avatar_url}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.user.name)+" ")])],1)],1),_c('div',{staticClass:"mr-2"},[_c('v-btn',{attrs:{"text":"","to":{ name: 'admin.users.index' }}},[_vm._v("return")])],1)])]),_c('v-card-text',[_c('v-form',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.user.email,"disabled":"","label":"Email"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.moment(_vm.user.email_verified_at).format('LLL'),"disabled":"","label":"Email Varified"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.moment(_vm.user.verify_at).format('LLL'),"disabled":"","label":"User Verified"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.user.slug,"disabled":"","label":"Slug"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.user.gender,"disabled":"","label":"Gender"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.user.bio,"disabled":"","label":"Bio"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.user.amazon,"disabled":"","label":"Amazon"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.user.withdrawDefault,"disabled":"","label":"Withdraw"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.moment(_vm.user.created_at).format('LLL'),"disabled":"","label":"Account created"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.moment(_vm.user.last_online_at).format('LLL'),"disabled":"","label":"Last Online"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.user.referrals,"headers":[
                        {
                            text: '# ID',
                            align: 'start',
                            sortable: false,
                            value: 'id',
                        },
                        { text: 'Status', value: 'referral.status' },
                        { text: 'Name', value: 'name' },
                        { text: 'Email', value: 'email' },
                        {
                            text: 'Email verified at',
                            value: 'email_verified_at',
                        },
                        { text: 'Created at', value: 'created_at' },
                        {
                            text: 'utm_source',
                            value: 'referral.utm_source',
                        } ]},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at ? _vm.moment(item.created_at).format("LLL") : "")+" ")]}},{key:"item.referral.status",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.referral.status === 1 ? "Pending" : item.referral.status === 2 ? "Approved" : item.referral.status === 3 ? "Blocked" : item.referral.status === 4 ? "Expired" : "-")+" ")]}},{key:"item.email_verified_at",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.email_verified_at ? _vm.moment(item.email_verified_at).format( "LLL" ) : "")+" ")]}}])}),_c('v-row',[(
                            _vm.user.accountBlocked &&
                            _vm.moment(_vm.user.accountBlocked).isValid()
                        )?_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.moment(_vm.user.accountBlocked).format('LLL'),"disabled":"","label":"Account blocked"}})],1):_vm._e()],1)],1),(_vm.user)?_c('user-actions',{attrs:{"user":_vm.user},on:{"handle-user-block":_vm.handleUserBlock,"handle-user-delete":_vm.handleUserDelete,"handle-document-expired":_vm.handleDocumentExpired,"handle-expire-all-subscriptions":_vm.handleExpireAllSubscriptions,"handle-confirm-last-subscription":_vm.handleConfirmLastSubscription,"handle-expire-all-direct-messages":_vm.handleExpireAllDirectMessages}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }