<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <div class="fill-width d-flex justify-space-between flex-wrap">
                    <div>
                        <v-list-item class="px-2">
                            <v-list-item-avatar>
                                <v-img :src="user.avatar_url"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-title>
                                {{ user.name }}
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                    <div class="mr-2">
                        <v-btn text :to="{ name: 'admin.users.index' }"
                            >return</v-btn
                        >
                    </div>
                </div>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field
                                :value="user.email"
                                disabled
                                label="Email"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                :value="
                                    moment(user.email_verified_at).format('LLL')
                                "
                                disabled
                                label="Email Varified"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                :value="moment(user.verify_at).format('LLL')"
                                disabled
                                label="User Verified"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                :value="user.slug"
                                disabled
                                label="Slug"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                :value="user.gender"
                                disabled
                                label="Gender"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                :value="user.bio"
                                disabled
                                label="Bio"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                :value="user.amazon"
                                disabled
                                label="Amazon"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                :value="user.withdrawDefault"
                                disabled
                                label="Withdraw"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                :value="moment(user.created_at).format('LLL')"
                                disabled
                                label="Account created"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                :value="
                                    moment(user.last_online_at).format('LLL')
                                "
                                disabled
                                label="Last Online"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-data-table
                        :items="user.referrals"
                        :headers="[
                            {
                                text: '# ID',
                                align: 'start',
                                sortable: false,
                                value: 'id',
                            },
                            { text: 'Status', value: 'referral.status' },
                            { text: 'Name', value: 'name' },
                            { text: 'Email', value: 'email' },
                            {
                                text: 'Email verified at',
                                value: 'email_verified_at',
                            },
                            { text: 'Created at', value: 'created_at' },
                            {
                                text: 'utm_source',
                                value: 'referral.utm_source',
                            },
                            // { text: 'utm_term', value: 'referral.utm_term' },
                            // {
                            //     text: 'utm_campaign',
                            //     value: 'referral.utm_campaign',
                            // },
                            // {
                            //     text: 'utm_medium',
                            //     value: 'referral.utm_medium',
                            // },
                            // {
                            //     text: 'utm_content',
                            //     value: 'referral.utm_content',
                            // },
                        ]"
                        class="elevation-1"
                    >
                        <template v-slot:item.created_at="{ item }">
                            {{
                                item.created_at
                                    ? moment(item.created_at).format("LLL")
                                    : ""
                            }}
                        </template>

                        <template v-slot:item.referral.status="{ item }">
                            {{
                                item.referral.status === 1
                                    ? "Pending"
                                    : item.referral.status === 2
                                    ? "Approved"
                                    : item.referral.status === 3
                                    ? "Blocked"
                                    : item.referral.status === 4
                                    ? "Expired"
                                    : "-"
                            }}
                        </template>

                        <template v-slot:item.email_verified_at="{ item }">
                            {{
                                item.email_verified_at
                                    ? moment(item.email_verified_at).format(
                                          "LLL"
                                      )
                                    : ""
                            }}
                        </template>
                    </v-data-table>

                    <v-row>
                        <v-col
                            v-if="
                                user.accountBlocked &&
                                moment(user.accountBlocked).isValid()
                            "
                        >
                            <v-text-field
                                :value="
                                    moment(user.accountBlocked).format('LLL')
                                "
                                disabled
                                label="Account blocked"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <user-actions
                    v-if="user"
                    :user="user"
                    @handle-user-block="handleUserBlock"
                    @handle-user-delete="handleUserDelete"
                    @handle-document-expired="handleDocumentExpired"
                    @handle-expire-all-subscriptions="
                        handleExpireAllSubscriptions
                    "
                    @handle-confirm-last-subscription="
                        handleConfirmLastSubscription
                    "
                    @handle-expire-all-direct-messages="
                        handleExpireAllDirectMessages
                    "
                />
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import User from "../../../models/admin/User";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import { mapActions, mapMutations } from "vuex";
import UserActions from "@/views/admin/users/components/UserActions.vue";

export default {
    name: "users-show",
    components: {
        UserActions,
        AdminDashboardLayout,
    },
    data: function () {
        return {
            user: {},
        };
    },
    computed: {
        userId() {
            const id = this.$route.params.id;
            if (id === undefined) {
                return undefined;
            }
            return +id;
        },
    },
    methods: {
        ...mapMutations(["showSnackBar"]),
        ...mapActions([
            "AdminBlockUser",
            "AdminDeleteUser",
            "AdminDocumentExpired",
        ]),
        async loadUser() {
            this.user = await User.find(this.userId);
            console.log(this.user);
        },
        handleUserDelete() {
            this.AdminDeleteUser(this.userId).then(() =>
                this.$router.push({ name: "admin.users.index" })
            );
        },
        handleUserBlock() {
            this.AdminBlockUser(this.userId).then(() => this.loadUser());
        },
        handleDocumentExpired() {
            this.AdminDocumentExpired(this.user.id)
                .then(() => {
                    this.loadUser(this.user.id);
                    this.showSnackBar(this.$t("messages.success"));
                })
                .catch(() =>
                    this.showSnackBar(this.$t("messages.something_went_wrong"))
                );
        },
        handleConfirmLastSubscription() {
            axios
                .post(
                    "api/admin/test/users/confirm-payment-last-subscription",
                    {
                        user_id: this.userId,
                    }
                )
                .then(() => this.showSnackBar(this.$t("messages.success")))
                .catch(() =>
                    this.showSnackBar(this.$t("messages.something_went_wrong"))
                );
        },
        handleExpireAllSubscriptions() {
            axios
                .post("api/admin/test/users/expire-all-subscriptions", {
                    user_id: this.userId,
                })
                .then(() => this.showSnackBar(this.$t("messages.success")))
                .catch(() =>
                    this.showSnackBar(this.$t("messages.something_went_wrong"))
                );
        },
        handleExpireAllDirectMessages() {
            axios
                .post("api/admin/test/users/expire-all-direct-messages", {
                    user_id: this.userId,
                })
                .then(() => this.showSnackBar(this.$t("messages.success")))
                .catch(() =>
                    this.showSnackBar(this.$t("messages.something_went_wrong"))
                );
        },
    },
    async created() {
        await this.loadUser(this.userId);
    },
};
</script>
