<template>
    <v-card>
        <v-card-title>Actions</v-card-title>
        <v-card-text>
            <v-btn text
                   class="btn-accent"
                   @click="openDialog(handleUserDelete)"
            >{{ 'delete' }}
            </v-btn>
            <v-btn text
                   class="btn-accent mx-4"
                   @click="openDialog(handleUserBlock)"
            >{{ user.accountBlocked ? 'unsuspend' : 'suspend' }}
            </v-btn>
            <v-btn text
                   class="btn-accent"
                   @click="openDialog(handleDocumentExpired)"
            >{{ 'document expired' }}
            </v-btn>
        </v-card-text>
        <v-card-title>Subscriptions</v-card-title>
        <v-card-text>
            <v-btn class="btn-accent"
                   text
                   @click="openDialog(handleConfirmLastSubscription)"
            >{{ 'Confirm Payment Last Subscription' }}
            </v-btn>
            <v-btn text
                   class="btn-accent mx-4"
                   @click="openDialog(handleExpireAllSubscriptions)"
            >{{ 'Expire All Subscriptions' }}
            </v-btn>
        </v-card-text>
        <v-card-title>Direct Message</v-card-title>
        <v-card-text>
            <v-btn text
                   class="btn-accent"
                   @click="openDialog(handleExpireAllDirectMessages)"
            >{{ 'Expire All Direct Messages' }}
            </v-btn>
        </v-card-text>
        <app-transition>
            <Dialog @no="closeDialog" @yes="confirmDialog" v-if="dialog.show">{{
                    $t('dialogs.are_you_sure')
                }}
            </Dialog>
        </app-transition>
    </v-card>
</template>

<script>
import Dialog from "@/components/app/setting/YesNoDialog.vue";

export default {
    name: "UserActions",
    components: {Dialog},
    props: {
        user: {
            type: Object,
        }
    },
    data: function () {
        return {
            dialog: {
                show: false,
                action: null,
            },
        }
    },
    methods: {
        handleUserDelete() {
            this.$emit('handle-user-delete')
        },
        handleUserBlock() {
            this.closeDialog()
            this.$emit('handle-user-block')
        },
        handleDocumentExpired() {
            this.$emit('handle-document-expired')
        },
        handleConfirmLastSubscription() {
            this.$emit('handle-confirm-last-subscription')
        },
        handleExpireAllSubscriptions() {
            this.$emit('handle-expire-all-subscriptions')
        },
        handleExpireAllDirectMessages() {
            this.$emit('handle-expire-all-direct-messages')
        },
        openDialog(action) {
            this.dialog.action = action
            this.$set(this.dialog, 'show', true)
        },
        closeDialog() {
            this.dialog.action = null
            this.$set(this.dialog, 'show', false)
        },
        confirmDialog() {
            this.dialog.action && this.dialog.action()
            this.closeDialog()
        },
    },
}
</script>
