<template>
    <div class="overlay">
        <v-container fluid class="h-full">

            <v-row align="center" justify="center" class="h-full">
                <v-col>
                    <div class="dialog">
                        <v-row justify="center" align="center" class="pa-6 title">
                            <slot/>
                        </v-row>
                        <v-row class="pb-6">
                            <v-col @click.stop="$emit('yes')" class="yes">
                                    <span  class="choose yes pt-3 pb-3 pl-7 pr-7">
                                        {{ $t('dialogs.yes') }}
                                    </span>
                            </v-col>
                            <v-col @click.stop="$emit('no')" class="no">
                                    <span  class="choose no pt-3 pb-3 pl-7 pr-7">
                                        {{ $t('dialogs.no') }}
                                    </span>
                            </v-col>
                        </v-row>
                        <slot name="afterButtons"></slot>
                    </div>
                </v-col>
            </v-row>

        </v-container>

    </div>
</template>

<script>
export default {
name: "YesNoDialog"
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';
.yes{
    text-align: end;
}
.choose{
    border: 1px solid white;
    border-radius: 13px;
    transition: 0.3s;
    cursor: pointer;
}
.choose:active{
    border: 1px solid white;
    border-radius: 13px;
    background: rgba(255,255,255,0.3);
}
.link {
    text-decoration: none;
    color: white;
    font-size: 1.3em;
}

.overlay {
    position: fixed;
    background: rgba(52, 52, 65, 0.88);
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.dialog {
    background: $accent-background;
    min-height: 140px;
    border-radius: 14px;
}
.title{
    font-size: 1.3em;
}
</style>
